import React, { useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useTheme } from "@material-ui/styles";

import Box from "@material-ui/core/Box";
import { closeTooltipModal } from "../actions/tooltip-modal";
import { modalProps } from "../selectors/tooltip-modal";
import { GenericTooltipModal } from "../components/ui/modals/Modal";
import { Body, H3, H4, SmallBody } from "../components/ui/Typography";
import {
  MODAL_OUT_OF_PROVINCE_WARNING_ASSET,
  REVSHARE_PARTNER_DISCOUNT_APPLIED,
  REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED,
  TOOLTIP_MODAL_BUNDLE_PLAN_UNDERLINE,
  TOOLTIP_MODAL_CHILDREN,
  TOOLTIP_MODAL_ESSENTIALS_PLAN_UNDERLINE,
  TOOLTIP_MODAL_IMPORTANT_NOTE,
  TOOLTIP_MODAL_PREMIUM_PLAN_UNDERLINE,
  TOOPTIP_MODAL_BUNDLE_SEPARATE_WILLS,
  VOLUME_PARTNER_DISCOUNT_APPLIED,
} from "../constants/modal";
import { OutOfProvince } from "../components/tooltip-modals/assets/OutOfProvince";
import EssentialsProductIcon from "../components/ui/icons/EssentialsProductIcon";
import { PrimaryButton } from "../components/ui/Button";

const List = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: ${(props) => props.paddingLeft}px;
`;

const OrderedList = styled.ol`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: ${(props) => props.paddingLeft}px;
`;

const TooltipWithListContent = ({ translations }) => {
  const theme = useTheme();
  const listPadding = theme.spacing(1.5);

  return (
    <>
      {translations.body && <SmallBody>{translations.body}</SmallBody>}
      <List paddingLeft={listPadding}>
        {translations.bulletList.map((item) => (
          <li key={item}>
            <SmallBody>{item}</SmallBody>
          </li>
        ))}
      </List>
    </>
  );
};

export const TooltipModal = ({
  modalKey,
  isVisible,
  closeTooltipModal,
  translations,
  ...restOfProps
}) => {
  const theme = useTheme();
  const listPadding = theme.spacing(1.5);
  const orderedListPadding = theme.spacing(1);
  const largeTitle = modalKey === "importantNotePage";
  const closeTooltipModalWithKey = useCallback(() => closeTooltipModal(modalKey), [closeTooltipModal, modalKey]);

  if (!isVisible) return null;

  let content;

  switch (modalKey) {
    case "plans.essentials":
    case "plans.premium":
    case "plans.bundle":
    case "plansComparisonTable.averageLifetimeSavings":
      content = <TooltipWithListContent translations={translations} />;
      break;
    case "executors.sectionPage":
    case "executorsDeprecated.sectionPage":
    case "allocationsGuidance.inheritanceAges":
    case "personalattorney.sectionPage":
    case "plansComparisonTable.registerWill":
    case "familyRecommendation.updates":
      content = (
        <Box>
          <Box mb={1}>
            <SmallBody>{translations.paragraph1}</SmallBody>
          </Box>
          <Box mb={1}>
            <SmallBody>{translations.paragraph2}</SmallBody>
          </Box>
        </Box>
      );
      break;
    case "propertyattorney.sectionPage":
      content = (
        <Box>
          <Box mb={1}>
            <SmallBody>{translations.paragraph1}</SmallBody>
          </Box>
          <Box mb={1}>
            <SmallBody>{translations.paragraph2}</SmallBody>
          </Box>
          <Box mb={1}>
            <SmallBody>{translations.paragraph3}</SmallBody>
          </Box>
          {/*  paragraph4 is not present in the generic tooltip modal translations */}
          {translations.paragraph4 && <Box mb={1}>
            <SmallBody>{translations.paragraph4}</SmallBody>
          </Box>}
        </Box>
      );
      break;
    case MODAL_OUT_OF_PROVINCE_WARNING_ASSET:
      content = <OutOfProvince translations={translations} />;
      break;
    case VOLUME_PARTNER_DISCOUNT_APPLIED:
    case REVSHARE_PARTNER_DISCOUNT_APPLIED:
    case REVSHARE_UNLIMITED_UPDATES_PARTNER_DISCOUNT_APPLIED:
      content = (
        <Box>
          <Box mb={1} textAlign="center">
            <EssentialsProductIcon width={110} height={110} />
          </Box>
          <Box mb={1}>
            <H3 align="left" role="heading" arial-level="1" tabIndex="0">
              {translations.modalTitle}
            </H3>
          </Box>
          <Box mb={2}>
            <Body role="note" aria-label={translations.body} tabIndex="0">
              {translations.body}
            </Body>
          </Box>
          <Box>
            <PrimaryButton
              onClick={closeTooltipModalWithKey}
              text={translations.button}
              fullWidth
            />
          </Box>
        </Box>
      );
      break;
    case TOOLTIP_MODAL_ESSENTIALS_PLAN_UNDERLINE:
    case TOOLTIP_MODAL_PREMIUM_PLAN_UNDERLINE:
    case TOOLTIP_MODAL_BUNDLE_PLAN_UNDERLINE:
    case TOOLTIP_MODAL_CHILDREN:
      content = (
        <Box>
          <div dangerouslySetInnerHTML={{ __html: translations.body }} />
        </Box>
      );
      break;
    case TOOPTIP_MODAL_BUNDLE_SEPARATE_WILLS:
      content = (
        <>
          {translations.body.map((bodyItem) => {
            return <p>{bodyItem}</p>;
          })}
        </>
      );
      break;
    case TOOLTIP_MODAL_IMPORTANT_NOTE:
      content = (
        <Box>
          <Box mb={1}>
            <Box mb={1}>
              <SmallBody>{translations.headerBody}</SmallBody>
            </Box>
            <Box mb={1}>
              <List paddingLeft={listPadding}>
                {translations.bullets.map((bullet, i) => (
                  <li key={`${i + 1}`}>
                    <SmallBody>{bullet.text}</SmallBody>
                  </li>
                ))}
              </List>
            </Box>
            <SmallBody>{translations.headerBottom}</SmallBody>
          </Box>

          <Box mb={1}>
            <H3 align="left">{translations.faqTitle}</H3>
          </Box>
          {translations.faqSections.map((section, i) => (
            <Box key={`faq-sections-${i + 1}`} mb={1}>
              <Box mb={1}>
                <H4 align="left">{section.title}</H4>
              </Box>
              <SmallBody>{section.body}</SmallBody>
              <OrderedList paddingLeft={orderedListPadding}>
                {section.list &&
                  section.list.map((item, j) => (
                    <li key={`faq-list-${i + 1}-${j + 1}`}>
                      <SmallBody>{item.text}</SmallBody>
                    </li>
                  ))}
              </OrderedList>
              {section.bodyBottom && (
                <SmallBody>{section.bodyBottom}</SmallBody>
              )}
            </Box>
          ))}
        </Box>
      );
      break;
    default:
      content = <SmallBody>{translations.body}</SmallBody>;
      break;
  }

  return (
    <GenericTooltipModal
      onClose={closeTooltipModalWithKey}
      title={translations.title}
      largeTitle={largeTitle}
      {...restOfProps}
    >
      {content}
    </GenericTooltipModal>
  );
};

export default connect(modalProps, {
  closeTooltipModal,
})(TooltipModal);
