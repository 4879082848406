// Relationships
export const SPOUSE_OR_PARTNER_RELATIONSHIP = "spouse_or_partner";
export const CHILD_RELATIONSHIP = "child";

// Badges
export const MINOR_CHILD_BADGE = "minor_child";
export const ADULT_CHILD_BADGE = "adult_child";

// Contact Dropdown Extra Options
export const ADD_NEW_CONTACT = "add-new-contact";
export const MANAGE_MY_CONTACTS = "manage-my-contacts";
