import React from "react";
import { reduxForm, InjectedFormProps } from "redux-form";
import { useSelector, TypedUseSelectorHook, RootStateOrAny } from "react-redux";
import { Box } from "@material-ui/core";
import { PrimaryButton } from "../../ui/Button";
import {
  CONTACT_FIELD_ID,
  CONTACT_MODAL_FORM_ID,
} from "../../../constants/forms";
import NameFields from "../../ui/inputs/NameFields";
import { selectLastSelectedContactData } from "../../../selectors/contacts";
import { SmallBody } from "../../ui/Typography";
const useTypedSelector: TypedUseSelectorHook<RootStateOrAny> = useSelector;

const ContactModal = ({
  invalid,
  handleSubmit,
  isLoading,
  translations,
  submitting,
}: InjectedFormProps & ContactModalProps) => {
  const contactData: ContactModalData = useTypedSelector(
    selectLastSelectedContactData,
  );
  return (
    <form onSubmit={handleSubmit}>
      <Box mt={1}>
        <NameFields
          useTheir
          fieldName={CONTACT_FIELD_ID}
          translations={translations}
          autoFocus={false}
          disableMiddleName={false}
        />
        {contactData.hasRoles && (
          <Box mb={2}>
            <SmallBody>{translations.rolesInfo}</SmallBody>
          </Box>
        )}
        <PrimaryButton
          fullWidth
          isLoading={isLoading}
          text={translations.button.submit}
          opacity={invalid ? 0.5 : 1}
          isDisabled={submitting}
        />
      </Box>
    </form>
  );
};

export default reduxForm<{}, ContactModalProps>({
  form: CONTACT_MODAL_FORM_ID,
})(ContactModal);
