export const openModalContact = (contact: ContactModalData) => ({
  type: OPEN_MODAL_CONTACT_TYPE,
  payload: { contact },
});

export const contactUpdatedSuccessfully = (contact: Contact) => ({
  type: UPDATE_CONTACT_SUCCESSFULLY_TYPE,
  payload: { contact },
});

export const notifyContact = ({
  email,
  id,
  firstName,
  lastName,
  roles,
  secondaryRoles,
}: NotifyContactPayload) => ({
  type: NOTIFY_CONTACT_TYPE,
  payload: {
    email,
    id,
    firstName,
    lastName,
    roles,
    secondaryRoles,
  },
});

export const updateLastSelectedContact = (contact: Contact) => ({
  type: UPDATE_LAST_SELECTED_CONTACT_TYPE,
  payload: { contact },
});

export const OPEN_MODAL_CONTACT_TYPE = "OPEN_MODAL_CONTACT";
export const UPDATE_CONTACT_SUCCESSFULLY_TYPE = "UPDATE_CONTACT_SUCCESSFULLY";
export const NOTIFY_CONTACT_TYPE = "NOTIFY_CONTACT";
export const UPDATE_LAST_SELECTED_CONTACT_TYPE = "UPDATE_LAST_SELECTED_CONTACT";
