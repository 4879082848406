import { takeEvery, call, put } from "redux-saga/effects";
import {
  SUBMIT_MODAL_TYPE,
  closeModal,
  SUBMIT_SECONDARY_MODAL_TYPE,
  displayModal,
  DISPLAY_MODAL_TYPE,
  CLOSE_MODAL_TYPE,
} from "../actions/modal";
import { validateLanguageCode } from "../actions/language";
import { deleteAccountRequest } from "./auth";
import { inviteUserRequest } from "./invite";
import { handleAssetListUpdate, handleAssetListRemove } from "./asset-list";
import {
  MODAL_DELETE_ACCOUNT,
  MODAL_INVITE_USER,
  FRENCH_NOT_AVAILABLE,
  MODAL_ADD_PHYSICAL_ASSET,
  MODAL_ADD_FINANCIAL_ASSET,
  MODAL_ADD_INSURANCE_ASSET,
  MODAL_ADD_BENEFICIARY_CHARITY,
  MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY,
  MODAL_ADD_BENEFICIARY_PERSON,
  MODAL_ADD_BACKUP_PERSON,
  MODAL_EDIT_BENEFICIARY_PERSON,
  MODAL_EDIT_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY,
  MODAL_REMOVE_BENEFICIARY,
  MODAL_ADD_EXECUTORS,
  MODAL_EDIT_EXECUTORS,
  MODAL_BNPL_EXPERIMENT,
  MODAL_ADD_CHILD_FORM,
  MODAL_EDIT_CHILD_FORM,
  MODAL_ADD_SPOUSE,
  MODAL_EDIT_SPOUSE,
  MODAL_ADD_PET,
  MODAL_EDIT_PET,
  MODAL_ADD_GUARDIAN,
  MODAL_EDIT_GUARDIAN,
  MODAL_ADD_PET_GUARDIAN,
  MODAL_EDIT_PET_GUARDIAN,
  MODAL_ADD_PROPERTY_ATTORNEY,
  MODAL_EDIT_PROPERTY_ATTORNEY,
  MODAL_ADD_PERSONAL_ATTORNEY,
  MODAL_EDIT_PERSONAL_ATTORNEY,
  MODAL_EDIT_CONTACT,
  MODAL_ADD_CONTACT,
  MODAL_DELETE_CONTACT,
} from "../constants/modal";
import {
  CHARITY_FORM_ID,
  CUSTOM_CHARITY_FORM_ID,
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
} from "../constants/forms";
import {
  handleAddBackupAllocation,
  handleAddPersonAllocation,
  handleAddCharity,
  handleUpdatePersonAllocation,
  handleUpdateCharityAllocation,
  handleUpdateCustomCharityAllocation,
  handleRemoveBeneficiary,
} from "./beneficiary";
import { handleAddOrEditExecutors } from "./executors";
import { handleBNPLExperiment } from "./checkout";
import {
  analyticsClosedModal,
  analyticsOpenedModal,
} from "../actions/analytics";
import {
  CLOSE_TOOLTIP_MODAL_TYPE,
  DISPLAY_TOOLTIP_MODAL_TYPE,
} from "../actions/tooltip-modal";
import { handleAddOrEditChildren } from "./children";
import { handleAddOrEditSpouse } from "./spouse";
import { handleAddOrEditPet } from "./pets";
import { handleAddOrEditPetGuardians } from "./pet-guardians";
import { handleAddOrEditPropertyAttorney } from "./property-attorneys";
import { handleAddOrEditChildrenGuardian } from "./children-guardians";
import { handleAddOrEditPersonalAttorney } from "./personal-attorneys";
import { handleAddOrEditContact, handleRemoveContact } from "./contacts";

function* submitModal({ payload }) {
  const { modalKey } = payload;

  switch (modalKey) {
    case MODAL_DELETE_ACCOUNT:
      return yield call(deleteAccountRequest);
    case MODAL_INVITE_USER:
      return yield call(inviteUserRequest);
    case FRENCH_NOT_AVAILABLE:
      return yield call(handleFrenchNotAvailable);
    case MODAL_ADD_PHYSICAL_ASSET:
      return yield call(handleAssetListUpdate, PHYSICAL_ASSETS_FORM_ID);
    case MODAL_ADD_FINANCIAL_ASSET:
      return yield call(handleAssetListUpdate, FINANCIAL_ASSETS_FORM_ID);
    case MODAL_ADD_INSURANCE_ASSET:
      return yield call(handleAssetListUpdate, INSURANCE_ASSETS_FORM_ID);
    case MODAL_ADD_BENEFICIARY_PERSON:
      return yield call(handleAddPersonAllocation);
    case MODAL_ADD_BENEFICIARY_CHARITY:
      return yield call(handleAddCharity, CHARITY_FORM_ID);
    case MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY:
      return yield call(handleAddCharity, CUSTOM_CHARITY_FORM_ID);
    case MODAL_ADD_BACKUP_PERSON:
      return yield call(handleAddBackupAllocation);
    case MODAL_EDIT_BENEFICIARY_PERSON:
      return yield call(handleUpdatePersonAllocation);
    case MODAL_EDIT_BENEFICIARY_CHARITY:
      return yield call(handleUpdateCharityAllocation);
    case MODAL_EDIT_BENEFICIARY_CUSTOM_CHARITY:
      return yield call(handleUpdateCustomCharityAllocation);
    case MODAL_REMOVE_BENEFICIARY:
      return yield call(handleRemoveBeneficiary);
    case MODAL_ADD_EXECUTORS:
      return yield call(handleAddOrEditExecutors);
    case MODAL_EDIT_EXECUTORS:
      return yield call(handleAddOrEditExecutors, true);
    case MODAL_BNPL_EXPERIMENT:
      return yield call(handleBNPLExperiment);
    case MODAL_ADD_CHILD_FORM:
      return yield call(handleAddOrEditChildren);
    case MODAL_EDIT_CHILD_FORM:
      return yield call(handleAddOrEditChildren, true);
    case MODAL_ADD_SPOUSE:
      return yield call(handleAddOrEditSpouse);
    case MODAL_EDIT_SPOUSE:
      return yield call(handleAddOrEditSpouse, true);
    case MODAL_ADD_PET:
      return yield call(handleAddOrEditPet);
    case MODAL_EDIT_PET:
      return yield call(handleAddOrEditPet, true);
    case MODAL_ADD_GUARDIAN:
      return yield call(handleAddOrEditChildrenGuardian);
    case MODAL_EDIT_GUARDIAN:
      return yield call(handleAddOrEditChildrenGuardian, true);
    case MODAL_ADD_PET_GUARDIAN:
      return yield call(handleAddOrEditPetGuardians);
    case MODAL_EDIT_PET_GUARDIAN:
      return yield call(handleAddOrEditPetGuardians, true);
    case MODAL_ADD_PROPERTY_ATTORNEY:
      return yield call(handleAddOrEditPropertyAttorney);
    case MODAL_EDIT_PROPERTY_ATTORNEY:
      return yield call(handleAddOrEditPropertyAttorney, true);
    case MODAL_ADD_PERSONAL_ATTORNEY:
      return yield call(handleAddOrEditPersonalAttorney);
    case MODAL_EDIT_PERSONAL_ATTORNEY:
      return yield call(handleAddOrEditPersonalAttorney, true);
    case MODAL_ADD_CONTACT:
      return yield call(handleAddOrEditContact);
    case MODAL_EDIT_CONTACT:
      return yield call(handleAddOrEditContact, true);
    case MODAL_DELETE_CONTACT:
      return yield call(handleRemoveContact);
    default:
      return yield put(closeModal());
  }
}

function* handleFrenchNotAvailable() {
  yield put(validateLanguageCode("en"));
  return yield put(closeModal());
}

function* handleSecondarySubmit({ payload }) {
  const { modalKey } = payload;
  switch (modalKey) {
    case MODAL_ADD_PHYSICAL_ASSET:
      return yield call(handleAssetListRemove, PHYSICAL_ASSETS_FORM_ID);
    case MODAL_ADD_FINANCIAL_ASSET:
      return yield call(handleAssetListRemove, FINANCIAL_ASSETS_FORM_ID);
    case MODAL_ADD_INSURANCE_ASSET:
      return yield call(handleAssetListRemove, INSURANCE_ASSETS_FORM_ID);
    case MODAL_ADD_BENEFICIARY_CHARITY:
      return yield put(displayModal(MODAL_ADD_CUSTOM_BENEFICIARY_CHARITY));
    default:
      return yield put(closeModal());
  }
}

function* handleDisplayModalType({ payload }) {
  const { key } = payload;
  const url = window.location.href;
  yield put(analyticsOpenedModal({ key, url }));
}

function* handleCloseModalType({ payload }) {
  if (!payload) return;
  const { key } = payload;
  if (key) {
    const url = window.location.href;
    yield put(analyticsClosedModal({ key, url }));
  }
}

export function* watchSubmitModal() {
  yield takeEvery(SUBMIT_MODAL_TYPE, submitModal);
}

export function* watchSecondarySubmitModal() {
  yield takeEvery(SUBMIT_SECONDARY_MODAL_TYPE, handleSecondarySubmit);
}

export function* watchOpenedModal() {
  yield takeEvery(
    [DISPLAY_MODAL_TYPE, DISPLAY_TOOLTIP_MODAL_TYPE],
    handleDisplayModalType,
  );
}

export function* watchClosedModal() {
  yield takeEvery(
    [
      CLOSE_MODAL_TYPE,
      CLOSE_TOOLTIP_MODAL_TYPE,
      SUBMIT_MODAL_TYPE,
      SUBMIT_SECONDARY_MODAL_TYPE,
    ],
    handleCloseModalType,
  );
}
