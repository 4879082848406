import R from "ramda";
import { change, getFormValues, startSubmit, stopSubmit } from "redux-form";
import { takeEvery, put, select, call } from "redux-saga/effects";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { deepTransformKeysToCamelCase } from "../utilities/helpers";
import {
  CONTACT_FIELD_ID,
  CONTACT_MODAL_FORM_ID,
  NOTIFICATIONS_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import {
  MODAL_ADD_CONTACT,
  MODAL_DELETE_CONTACT,
  MODAL_EDIT_CONTACT,
  MODAL_UNABLE_TO_DELETE_CONTACT,
} from "../constants/modal";
import { closeModal, displayModal } from "../actions/modal";
import {
  contactUpdatedSuccessfully,
  NOTIFY_CONTACT_TYPE,
  OPEN_MODAL_CONTACT_TYPE,
} from "../actions/contacts";
import { fetchDashboard } from "../actions/dashboard";
import {
  ADD_CONTACT_SUCCESS,
  EDIT_CONTACT_SUCCESS,
  REMOVE_CONTACT_SUCCESS,
  SEND_NOTIFY_CONTACT_SUCCESS,
} from "../constants/toasts";
import { submitFormRequest } from "./forms";
import {
  submitContactNotification,
  submitContactEndpoint,
  removeContactEndpoint,
} from "../api/contacts";
import { selectLastSelectedContactData } from "../selectors/contacts";

function* updateStatusInContacts({ payload }) {
  const {
    user: { contacts },
  } = payload;
  if (contacts.length === 0) {
    return yield put({ type: CONTACTS_FORMATTED_TYPE, contacts });
  }
  const formattedContactPayload = deepTransformKeysToCamelCase(contacts);
  return yield put({
    type: CONTACTS_FORMATTED_TYPE,
    payload: formattedContactPayload,
  });
}

function* updateContact(contactToUpdate) {
  const { status, contact } = yield call(submitFormRequest, {
    apiCall: submitContactEndpoint,
    formId: CONTACT_MODAL_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), contactToUpdate),
      firstName: R.propOr(null, "firstName")(contactToUpdate),
      middleName: R.propOr(null, "middleName")(contactToUpdate),
      lastName: R.propOr(null, "lastName")(contactToUpdate),
    },
  });
  if (status !== 200 || !contact) {
    yield put(stopSubmit(CONTACT_MODAL_FORM_ID));
    return null;
  }
  return yield put(fetchDashboard());
}

function* handleOpenModalContact({ payload }) {
  yield put(closeToast());
  const contact = R.propOr(null, "contact")(payload);
  const isDestroy = R.propOr(false, "isDestroy")(contact);
  if (contact) {
    if (isDestroy) {
      // if destroy flag is present, open delete modal
      const deleteModalKy = contact.hasRoles
        ? MODAL_UNABLE_TO_DELETE_CONTACT
        : MODAL_DELETE_CONTACT;
      return yield put(displayModal(deleteModalKy));
    }
    yield put(change(CONTACT_MODAL_FORM_ID, CONTACT_FIELD_ID, contact));
    return yield put(displayModal(MODAL_EDIT_CONTACT));
  }
  return yield put(displayModal(MODAL_ADD_CONTACT));
}

export function* handleAddOrEditContact(isEditing = false) {
  yield put(startSubmit(CONTACT_MODAL_FORM_ID));
  try {
    const formData = yield select(getFormValues(CONTACT_MODAL_FORM_ID));
    const { contact } = formData;
    if (contact) {
      yield call(updateContact, contact);
      yield put(contactUpdatedSuccessfully(contact));
    }
    yield put(closeModal());
    yield put(
      displayToast(isEditing ? EDIT_CONTACT_SUCCESS : ADD_CONTACT_SUCCESS),
    );
  } catch (error) {
    yield put(stopSubmit(CONTACT_MODAL_FORM_ID, error.formErrors));
  }
}

export function* handleRemoveContact() {
  const contactToDelete = yield select(selectLastSelectedContactData);
  const { status, contact } = yield call(submitFormRequest, {
    apiCall: removeContactEndpoint,
    formId: CONTACT_MODAL_FORM_ID,
    values: {
      ...contactToDelete,
    },
  });
  yield put(closeModal());
  yield put(closeToast());
  if (status !== 200 || !contact) {
    return yield put(stopSubmit(CONTACT_MODAL_FORM_ID));
  }
  return yield put(displayToast(REMOVE_CONTACT_SUCCESS));
}

function* handleNotifyContact(action) {
  const {
    email,
    id,
    firstName,
    lastName,
    roles,
    secondaryRoles,
  } = action.payload;

  const { status } = yield call(submitFormRequest, {
    apiCall: submitContactNotification,
    formId: NOTIFICATIONS_FORM_ID,
    values: {
      email,
      contactId: id,
      firstName,
      lastName,
      roles,
      secondaryRoles,
    },
  });
  if (status !== 200) {
    return yield put(stopSubmit(NOTIFICATIONS_FORM_ID));
  }
  return yield put(displayToast(SEND_NOTIFY_CONTACT_SUCCESS));
}

export function* watchUpdateStatusInContacts() {
  yield takeEvery(UPDATE_STATUS_TYPE, updateStatusInContacts);
}

export function* watchOpenModalContact() {
  yield takeEvery(OPEN_MODAL_CONTACT_TYPE, handleOpenModalContact);
}

export function* watchNotifyContact() {
  yield takeEvery(NOTIFY_CONTACT_TYPE, handleNotifyContact);
}

export const CONTACTS_FORMATTED_TYPE = "CONTACTS_FORMATTED";
