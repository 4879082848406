import React from "react";
import CSS from "csstype";
import Box from "@material-ui/core/Box";
import { Body } from "./Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContactBadgesTranslations,
  selectContactRolesTranslations,
} from "../../selectors/contacts";
import { SecondaryButton, SmallTextButton } from "./Button";
import { selectGlobalNavigationTranslations } from "../../selectors/navigation";
import { colorPalette } from "../../config/Theme";
import { getContactBadgeKey } from "./utils/contacts";
import {
  notifyContact,
  openModalContact,
  updateLastSelectedContact,
} from "../../actions/contacts";
import { buildFullName } from "../../utilities/name";

const FamilyBadge = ({ person }: { person: ContactPayload }) => {
  const badges = useSelector(selectContactBadgesTranslations);
  const badgeStyles: CSS.Properties = {
    backgroundColor: colorPalette.willfulxxxLightBlue,
    color: colorPalette.willfulBlue,
    padding: "4px 16px",
    marginLeft: "8px",
    minWidth: "fit-content",
    maxHeight: "fit-content",
    borderRadius: "4px",
  };
  const badgeKey = getContactBadgeKey(person);
  return <div style={badgeStyles}>{badges[badgeKey]}</div>;
};

const ContactCardRoles = ({ person }: { person: ContactPayload }) => {
  // For each contact, the "roles" are based on the ContactType.contactable_type for each ContactType on a Contact
  // The roles verbiage can vary by province, but the ContactType.contactable_type will be consistent
  // We iterate through the contactsPage.roles (in the language json files) using the contactable_type as the key
  const roles = useSelector(selectContactRolesTranslations);
  const roleStyles: CSS.Properties = {
    color: colorPalette.willfulGrey1,
  };
  const allContactTypes: ContactType[] = [
    ...person.contactTypes,
    ...person.contactTypesForSecondaryContact,
  ];
  return (
    <>
      {allContactTypes.length != 0 &&
        allContactTypes.map((ct: ContactType, index: number) => {
          const isNotFirst = index != 0;
          return (
            <span style={roleStyles} key={ct.id}>
              {isNotFirst
                ? ` | ${roles[ct.contactableType]}`
                : roles[ct.contactableType]}{" "}
            </span>
          );
        })}
    </>
  );
};

export const ContactCard = ({
  person,
  showBadge = false,
}: {
  person: ContactPayload;
  showBadge?: boolean;
}) => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectGlobalNavigationTranslations);
  const {
    firstName,
    middleName,
    lastName,
    email,
    relationship,
    id,
  } = person.contact;
  const fullName = buildFullName(firstName, middleName, lastName);
  const hasRoles = Boolean(
    person.contactTypes.length || person.contactTypesForSecondaryContact.length,
  );
  const contactInfoForModal = {
    ...person.contact,
    hasRoles,
  };
  const shouldDisplayNotify = hasRoles && email !== null;

  return (
    <Box
      bgcolor="willfulWhite.main"
      border={1}
      borderRadius={4}
      borderColor="border.main"
      padding={1.5}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box display="flex" justifyContent="space-between">
        <Body component="span" bold>
          {fullName}
        </Body>
        {showBadge && <FamilyBadge person={person} />}
      </Box>
      <Box pt={1.5}>
        <ContactCardRoles person={person} />
      </Box>
      <Box display="flex" justifyContent={"space-between"} pt={1.5}>
        <Box display="flex">
          <SmallTextButton
            onClick={() => dispatch(openModalContact(contactInfoForModal))}
            text={navigation.edit}
            includeHorizontalSpacing
            className="qa-edit-contact"
          />
          <SmallTextButton
            onClick={() =>
              dispatch(
                openModalContact({ ...contactInfoForModal, isDestroy: true }),
              )
            }
            text={navigation.remove}
            includeHorizontalSpacing
            className="qa-remove-contact"
          />
        </Box>
        {shouldDisplayNotify && (
          <Box>
            <SecondaryButton
              onClick={() => {
                dispatch(updateLastSelectedContact(person.contact));
                dispatch(
                  notifyContact({
                    email,
                    id: id,
                    firstName: firstName,
                    lastName: lastName,
                    roles: person.contactTypes,
                    secondaryRoles: person.contactTypesForSecondaryContact,
                  }),
                );
              }}
              text={navigation.notify}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
